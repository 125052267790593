.sp {
  position: relative;
  display: inline-block;
  &::before {
    display: inline-block;
    content: ' ';
    vertical-align: middle;
    background-image: url('../../.sprites/sprite.png');
  }
}

.sp-call:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -66px -72px;
  width: 14px;
  height: 14px;
}
.sp-email:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -63px -55px;
  width: 16px;
  height: 12px;
}
.sp-location:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -118px -56px;
  width: 11px;
  height: 14px;
}
.sp-org:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -85px -72px;
  width: 14px;
  height: 14px;
}
.sp-qq:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -63px 0px;
  width: 50px;
  height: 50px;
}
.sp-qq_2:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: 0px -72px;
  width: 26px;
  height: 31px;
}
.sp-star:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -118px -19px;
  width: 14px;
  height: 13px;
}
.sp-sticker:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -118px 0px;
  width: 14px;
  height: 14px;
}
.sp-success:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: 0px 0px;
  width: 58px;
  height: 67px;
}
.sp-user:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -118px -37px;
  width: 12px;
  height: 14px;
}
.sp-wechat:before {
  // data-bak-background-image: data-bak-url(../../.sprites/sprite.png);
  background-position: -31px -72px;
  width: 30px;
  height: 24px;
}
