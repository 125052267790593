.contacts-footer {
  background-color: #312f38;
  color: #ffffff;
  padding-top: 60px;
  // padding-bottom: 8px;
}

.contacts-footer-item {
  width: 50%;
  margin-bottom: 60px;
  padding-right: 40px;
  float: left;
  @media (max-width: 992px) {
    width: 100%;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.contacts-footer-3 .contacts-footer-item {
  width: calc(100% / 3);
}

.contacts-footer-item-content {
  // padding-left: 10px;
  max-width: 355px;
  line-height: 1.6em;
}

.contacts-footer-item-content-disabled {
  display: none;
}

.contacts-footer-title {
  color: #ffffff;
  padding-left: 10px;
  border-left: 3px solid #02a9f4;
  margin-left: -10px;
  font-size: 18px;
  margin-bottom: 15px;
}

.contacts-footer-content {
  font-size: 14px;
  p {
    font-weight: 300;
    line-height: 1.5;
  }
  i {
    text-align: center;
    width: 20px;
    font-size: 18px;
    margin-right: 6px;
  }
}

.footer-icon {
  display: inline-block;
  height: 60px;
  width: 52px;
  vertical-align: middle;
  background-image: url('../../assets/images/footer_icon.png');
  background-size: cover;
}

.contacts-footer-3 .contacts-footer-icon-container {
  padding-top: 16px;
}

.contacts-footer-3 .contacts-footer-icon {
  padding-right: 4px;
}

.contacts-footer-3 .contacts-footer-icon,
.contacts-footer-3 .contacts-footer-content-inline {
  display: inline-block;
  font-size: 12px;
  line-height: 1.8;
}

.contacts-footer-3 .contacts-footer-content-inline {
  vertical-align: middle;

  @media (max-width: 1200px) {
    width: 246px;
  }
  @media (max-width: 992px) {
    width: auto;
  }
}

.contacts-footer-item.contacts-footer-item-content-no-icon {
  padding-top: 37px;
  .contacts-footer-content-item {
    font-size: 14px;
    line-height: 1.5;
  }
}

.contacts-footer-3 .contacts-footer-content-item {
  display: block;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.copyright-footer {
  background: #242229;
  color: #ccc;
  color: rgba(255, 255, 255, 0.8);
  padding: 16px;
  font-size: 12px;
  a {
    color: #ccc;
  }
}

.back-to-top {
  animation-duration: 1s;
  animation-fill-mode: both;
  width: 50px;
  height: 50px;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  color: #555;
  border-radius: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  transition: .3s ease-out;
  z-index: 11;
  text-align: center;
  line-height: 50px;
  position: fixed;
  right: 15px;
  bottom: 15px;
}
