.home-banner {
  height: 100%;
}
.home-banner-item {
  background-color: #2974D7;
  background-repeat: no-repeat;
}
// 用户角色几个 card
.home-user-role-section {
  height: auto;
  background: #f8f8f8;
  padding-top: 116px;
  padding-bottom: 56px;
  border-bottom: 1px solid #eee;
}
.home-user-role-card {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  min-height: 340px;
  max-width: 240px;
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 220px;
    min-height: 360px;
  }
  border-radius: 10px;
  background: #ffffff;
  text-align: center;
}
.home-user-role-img-container {
  padding-top: 5px;
  text-align: center;
  img {
    display: block;
    margin: auto auto;
  }
}
.home-user-role-title {
  margin-top: 30px;
  margin-bottom: 14px;
  font-size: 24px;
  color: #333;
}
.home-user-role-desc {
  margin: 0px auto;
  font-size: 16px;
  color: #666666;
  padding-left: 35px;
  padding-right: 35px;
}

// 蓝色的使用记录
.home-usage-section {
  // margin-top: 40px;
  padding-top: 50px;
  background: #3398dc;
  border-bottom: 1px solid #eee;
}
.home-usage {
  @media (max-width: 992px) {
    &:nth-child(2n) {
      border-right: 0;
    }
  }
  margin-bottom: 50px;
  text-align: center;
  color: #ffffff;
  &:last-child {
    border-right: 0;
  }
}
.home-usage-num {
  font-size: 35px;
  font-weight: 800;
  margin-bottom: 7px;
}
.home-usage-name {
  font-size: 18px;
  color: #fff;
}

// 优秀空间
.home-space-section {
  padding: 0 15px;
}
.home-space-header {
  text-align: center;
  font-size: 40px;
  margin-top: 78px;
  margin-bottom: 50px;
}
.home-space-section-nav {
  text-align: center;
  list-style: none;
}
.home-space-section-nav-content {
  padding-bottom: 20px;
}
.home-space-section-nav-item {
  display: inline-block;
  box-sizing: border-box;
  width: 150px;
  height: 42px;
  line-height: 42px;
  border-right: 2px solid #e5e5ea;
  margin-bottom: 20px;
  font-size: 18px;
  &.active {
    color: #03a9f4;
  }
  &:last-child {
    border-right: 0;
  }
  @media (max-width: 700px) {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.home-space-detail-container {
  padding: 20px;
}
.home-space-icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  overflow: hidden;
  margin-right: 10px;
  float: left;
  > img {
    width: 100%;
    height: 100%;
  }
}
.home-space-desc-container {
}
.home-space-desc-name {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.home-space-desc-detail {
  font-size: 14px;
  font-weight: 400px;
}

// 友情链接
.home-link-section {
  background-color: #f8f8f8;
  // margin-top: 56px;
  // padding: 0 15px 100px 15px;
  margin-top: 0;
  padding: 56px 15px 100px 15px;
}
.home-link-header {
  text-align: center;
  font-size: 40px;
  padding-top: 78px;
  margin-bottom: 70px;
}
.home-link-item {
  margin-bottom: 20px;
}
.home-link-item-icon {
  margin-right: 10px;
  display: inline-block;
  width: 24px;
}
.home-link-item-name {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  &:visited, &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.activity-mask {
  background-color: rgba($color: #000000, $alpha: 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
.activity-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 540px;
  text-align: center;
  padding-top: 60px;
  background-color: #ffffff;
  color: #333333;
  border-radius: 10px;
}
.activity-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 50px;
}
.activity-img {
  width: 145px;
  height: 150px;
  margin: 10px auto 44px auto;
}

.activity-link {
  margin-top: 10px;
  color: #1890ff;
}
.activity-link:hover {
  color: #1890ff;
}
.activity-close {
  width: 105px;
  height: 35px;
  color: #ffffff;
  background-color: #1890ff;
  margin: 40px auto;
  line-height: 35px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}